// React Imports
import type { Theme } from '@mui/material';
import React from 'react';

// MUI Imports

const Icon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.67047 19.8614C5.2413 19.8614 4.87839 19.7131 4.58172 19.4164C4.28505 19.1198 4.13672 18.7568 4.13672 18.3277V5.67218C4.13672 5.24301 4.28505 4.8801 4.58172 4.58343C4.87839 4.28676 5.2413 4.13843 5.67047 4.13843H18.326C18.7551 4.13843 19.1181 4.28676 19.4147 4.58343C19.7114 4.8801 19.8597 5.24301 19.8597 5.67218V18.3277C19.8597 18.7568 19.7114 19.1198 19.4147 19.4164C19.1181 19.7131 18.7551 19.8614 18.326 19.8614H5.67047ZM5.67522 18.9384H18.3212C18.4751 18.9384 18.6161 18.8743 18.7445 18.7462C18.8726 18.6178 18.9367 18.4768 18.9367 18.3229V5.67693C18.9367 5.5231 18.8726 5.38201 18.7445 5.25368C18.6161 5.12551 18.4751 5.06143 18.3212 5.06143H5.67522C5.52139 5.06143 5.3803 5.12551 5.25197 5.25368C5.1238 5.38201 5.05972 5.5231 5.05972 5.67693V18.3229C5.05972 18.4768 5.1238 18.6178 5.25197 18.7462C5.3803 18.8743 5.52139 18.9384 5.67522 18.9384Z'
        fill='currentColor'
      />
    </svg>
  );
};

const IndeterminateIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.5 12.5H16.5V11.5H7.5V12.5ZM5.6155 20C5.15517 20 4.77083 19.8458 4.4625 19.5375C4.15417 19.2292 4 18.8448 4 18.3845V5.6155C4 5.15517 4.15417 4.77083 4.4625 4.4625C4.77083 4.15417 5.15517 4 5.6155 4H18.3845C18.8448 4 19.2292 4.15417 19.5375 4.4625C19.8458 4.77083 20 5.15517 20 5.6155V18.3845C20 18.8448 19.8458 19.2292 19.5375 19.5375C19.2292 19.8458 18.8448 20 18.3845 20H5.6155ZM5.6155 19H18.3845C18.5385 19 18.6796 18.9359 18.8077 18.8077C18.9359 18.6796 19 18.5385 19 18.3845V5.6155C19 5.4615 18.9359 5.32042 18.8077 5.19225C18.6796 5.06408 18.5385 5 18.3845 5H5.6155C5.4615 5 5.32042 5.06408 5.19225 5.19225C5.06408 5.32042 5 5.4615 5 5.6155V18.3845C5 18.5385 5.06408 18.6796 5.19225 18.8077C5.32042 18.9359 5.4615 19 5.6155 19Z'
        fill='currentColor'
      />
    </svg>
  );
};

const CheckedIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.6137 15.449L16.902 9.16055L16.2405 8.49905L10.6137 14.1261L7.79447 11.3068L7.13272 11.9683L10.6137 15.449ZM5.67047 19.8616C5.2413 19.8616 4.87839 19.7132 4.58172 19.4165C4.28505 19.1199 4.13672 18.757 4.13672 18.3278V5.6723C4.13672 5.24313 4.28505 4.88022 4.58172 4.58355C4.87839 4.28688 5.2413 4.13855 5.67047 4.13855H18.326C18.7551 4.13855 19.1181 4.28688 19.4147 4.58355C19.7114 4.88022 19.8597 5.24313 19.8597 5.6723V18.3278C19.8597 18.757 19.7114 19.1199 19.4147 19.4165C19.1181 19.7132 18.7551 19.8616 18.326 19.8616H5.67047Z'
        fill='currentColor'
      />
    </svg>
  );
};

const checkbox: Theme['components'] = {
  MuiCheckbox: {
    defaultProps: {
      size: 'small',
      disableRipple: true,
      icon: <Icon />,
      indeterminateIcon: <IndeterminateIcon />,
      checkedIcon: <CheckedIcon />,
    },
    styleOverrides: {
      root: () => ({
        padding: 0,
      }),
    },
  },
};

export default checkbox;
