// MUI Imports
import type { Theme } from '@mui/material';

const paper: Theme['components'] = {
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: 'none',
      },
    },
  },
};

export default paper;
