import type { Theme } from '@mui/material';

const popover = (): Theme['components'] => ({
  MuiPopover: {
    styleOverrides: {
      paper: {
        boxShadow: 'var(--mui-customShadows-sm)',
      },
    },
  },
});

export default popover;
