// MUI Imports
import type { Theme } from '@mui/material';

const tooltip: Theme['components'] = {
  MuiTooltip: {
    defaultProps: {
      placement: 'top',
    },
    styleOverrides: {
      tooltip: ({ theme }) => ({
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: 1.539,
        color: 'var(--mui-palette-customColors-tooltipText)',
        padding: '8px',
        borderRadius: '8px',
        backgroundColor:
          theme.palette.mode === 'light' ? 'rgba(39, 39, 66, 0.90)' : 'white',
      }),
    },
  },
};

export default tooltip;
