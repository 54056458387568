// MUI Imports
import type { Theme } from '@mui/material';

const input: Theme['components'] = {
  MuiInputBase: {
    styleOverrides: {
      root: {
        lineHeight: 1.6,
        '&.MuiInput-underline': {
          '&:before': {
            borderColor: 'var(--mui-palette-customColors-inputBorder)',
          },
          '&:not(.Mui-disabled, .Mui-error):hover:before': {
            borderColor: 'var(--mui-palette-action-active)',
          },
        },
        '&.Mui-disabled .MuiInputAdornment-root, &.Mui-disabled .MuiInputAdornment-root > *':
          {
            color: 'var(--mui-palette-action-disabled)',
          },
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        '&:before': {
          borderBottom: '1px solid var(--mui-palette-text-secondary)',
        },
        '&.Mui-disabled:before': {
          borderBottomStyle: 'solid',
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      shrink: ({ ownerState }) => ({
        ...(ownerState.variant === 'outlined' && {
          color: 'var(--mui-palette-text-secondary)',
          transform: 'translate(14px, -8px) scale(0.867)',
        }),
        ...(ownerState.variant === 'filled' && {
          transform: 'translate(12px, 7px) scale(0.867)',
        }),
        ...(ownerState.variant === 'standard' && {
          transform: 'translate(0, -1.5px) scale(0.867)',
        }),
      }),
    },
  },
  MuiOutlinedInput: {
    defaultProps: {
      size: 'small',
    },
    styleOverrides: {
      root: {
        '&:not(.Mui-focused):not(.Mui-error):not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline':
          {
            borderColor: 'var(--mui-palette-action-active)',
          },
        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--mui-palette-action-disabledBackground)',
        },
      },
      input: () => ({
        padding: '6px 12px',
        '& ~ .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--mui-palette-customColors-inputBorder)',
        },
      }),
      notchedOutline: {
        '& legend': {
          fontSize: '0.867em',
        },
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        color: 'var(--mui-palette-text-primary)',
        '& i, & svg': {
          fontSize: '1.25rem',
        },
        '& *': {
          color: 'inherit !important',
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        lineHeight: 1,
        letterSpacing: 'unset',
      },
    },
  },
};

export default input;
