// MUI Imports
import type { Theme } from '@mui/material';

const switchOverrides: Theme['components'] = {
  MuiSwitch: {
    defaultProps: {
      disableRipple: true,
      size: 'small',
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        ...(ownerState.size !== 'small'
          ? {
              width: 46,
              height: 36,
              padding: theme.spacing(2.25, 2),
            }
          : {
              width: 36,
              height: 20,
              padding: 0,
              '& .MuiSwitch-thumb': {
                width: 10,
                height: 10,
                padding: 0,
              },
              '& .MuiSwitch-switchBase': {
                padding: 3,
                left: 3,
                '&.Mui-checked': {
                  left: 1,
                },
              },
            }),
      }),
      switchBase: {
        // the disabled circle
        top: 2,
        left: 1,
        color: 'var(--mui-palette-background-paper)',
        '&.Mui-checked': {
          // the active circle
          left: -7,
          color: 'var(--mui-palette-background-paper)',
          '& + .MuiSwitch-track': {
            // the switch background active
            opacity: 1,
            backgroundColor: 'var(--mui-palette-success-main)',
          },
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 1,
        },
        '&:hover:not(:has(span.MuiTouchRipple-root))': {
          backgroundColor: 'transparent',
        },
      },
      thumb: {
        width: 14,
        height: 14,
        boxShadow: 'var(--mui-customShadows-xs)',
      },
      track: ({ theme }) => ({
        opacity: theme.palette.mode === 'light' ? 1 : 0.25,
        borderRadius: 10,
        // the switch background disabled
        backgroundColor:
          theme.palette.mode === 'light'
            ? 'var(--mui-palette-background-lightGray)'
            : 'white',
        boxShadow: `0 0 4px rgb(var(--mui-palette-common-${
          theme.palette.mode === 'light'
            ? 'onBackgroundChannel'
            : 'backgroundChannel'
        }) / 0.16) inset`,
      }),
    },
  },
};

export default switchOverrides;
