import type { Theme } from '@mui/material';

const formLabel: Theme['components'] = {
  MuiFormLabel: {
    styleOverrides: {
      root: () => ({
        color: 'var(--mui-palette-text-primary)',
        marginBottom: '12px',
      }),
    },
  },
};

export default formLabel;
