'use client';

import { useRouter } from 'next/navigation';
import { createContext, type PropsWithChildren } from 'react';

export const RoutingContext = createContext<{
  back: () => void;
  push: (url: string) => void;
  canGoBack: () => boolean;
} | null>(null);

export const RoutingProvider = ({ children }: PropsWithChildren) => {
  let lastPageUrl: { pathName: string; search: string } | undefined;
  const router = useRouter();

  const push = (url: string) => {
    // in case someone using routing.push on the same page, we don't want to lose the last page url
    // we had, of the previous page.
    if (!url.startsWith(window.location.pathname)) {
      lastPageUrl = {
        pathName: window.location.pathname,
        search: window.location.search,
      };
    }
    router.push(url);
  };

  const back = () => {
    if (lastPageUrl) {
      router.push(lastPageUrl.pathName + lastPageUrl.search);
    }
  };

  const isLastPageAvailable = () => {
    return !!lastPageUrl;
  };

  return (
    <RoutingContext.Provider
      value={{
        push,
        back,
        canGoBack: isLastPageAvailable,
      }}
    >
      {children}
    </RoutingContext.Provider>
  );
};
