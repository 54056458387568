import type { Theme } from '@mui/material';

const dialog = (): Theme['components'] => ({
  MuiDialog: {
    styleOverrides: {
      paper: {
        boxShadow: 'var(--mui-customShadows-xl)',
      },
    },
  },
  MuiDialogTitle: {
    defaultProps: {
      variant: 'h5',
    },
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(5),
        '& + .MuiDialogActions-root': {
          paddingTop: 0,
        },
      }),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(5),
        '& + .MuiDialogContent-root, & + .MuiDialogActions-root': {
          paddingTop: 0,
        },
      }),
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(5),
        '&:where(.dialog-actions-dense)': {
          padding: theme.spacing(2.5),
          '& .MuiButton-text': {
            paddingInline: theme.spacing(2.5),
          },
        },
      }),
    },
  },
});

export default dialog;
