import type { Theme } from '@mui/material';

const stepper: Theme['components'] = {
  MuiStepLabel: {
    styleOverrides: {
      labelContainer: {
        '.MuiStepLabel-alternativeLabel': {
          marginTop: '12px',
        },
      },
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      root: {
        left: '-50%',
        right: '50%',
      },
      line: {
        borderColor: 'var(--mui-palette-primary-main)',
      },
    },
  },
};

export default stepper;
