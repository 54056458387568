'use client';

import {
  CssBaseline,
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
  useColorScheme,
} from '@mui/material';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v14-appRouter';
import {
  createContext,
  useEffect,
  useMemo,
  type PropsWithChildren,
} from 'react';
import type {} from '@mui/material/themeCssVarsAugmentation'; //! Do not remove this import otherwise you will get type errors while making a production build
import type {} from '@mui/lab/themeAugmentation'; //! Do not remove this import otherwise you will get type errors while making a production build

import defaultCoreTheme from '@core/theme';
import type { SystemMode } from '@core/types';

import themeConfig from '@/configs/themeConfig';
import { useDAL } from '@/data/dal';
import { PreferencesKey } from '@/data/dal/preferences';

export const ThemeContext = createContext<{
  theme: SystemMode;
  changeTheme: (theme: SystemMode) => void;
}>({ theme: 'light', changeTheme: () => {} });

export const ThemeProvider = (
  props: PropsWithChildren<{ isAuthenticated: boolean }>
) => {
  if (props.isAuthenticated) {
    return <AuthThemeProvider>{props.children}</AuthThemeProvider>;
  }

  return (
    <InnerThemeProvider currentMode={'light'}>
      {props.children}
    </InnerThemeProvider>
  );
};

const AuthThemeProvider = (props: PropsWithChildren) => {
  const dal = useDAL();
  const { body } = dal.preferences.getUserPref(PreferencesKey.Preferences);

  const currentMode: SystemMode = body?.value?.theme || 'light';

  return (
    <InnerThemeProvider currentMode={currentMode}>
      {props.children}
    </InnerThemeProvider>
  );
};

const InnerThemeProvider = (
  props: PropsWithChildren<{ currentMode: SystemMode }>
) => {
  // Merge the primary color scheme override with the core theme
  const theme = useMemo(() => {
    const coreTheme = defaultCoreTheme(props.currentMode);
    return extendTheme(coreTheme);
  }, [props.currentMode]);

  return (
    <AppRouterCacheProvider
      options={{
        prepend: true,
      }}
    >
      <CssVarsProvider
        theme={theme}
        defaultMode={'light'}
        modeStorageKey={`${themeConfig.templateName.toLowerCase().split(' ').join('-')}-mui-template-mode`}
      >
        <ThemeWrapper theme={props.currentMode}>
          <CssBaseline />
          {props.children}
        </ThemeWrapper>
      </CssVarsProvider>
    </AppRouterCacheProvider>
  );
};

const ThemeWrapper = ({
  children,
  theme,
}: PropsWithChildren<{ theme: SystemMode }>) => {
  const dal = useDAL();
  const { mode, setMode } = useColorScheme();

  useEffect(() => {
    setMode(theme);
  }, [setMode, theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme: mode === 'dark' ? 'dark' : 'light',
        changeTheme: (t) => {
          setMode(t);
          void dal.preferences.updateUserPref(PreferencesKey.Preferences, {
            theme: t,
          });
        },
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
