'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
} from 'react';

import { toJsonText } from '@/components/queryBuilder/QueryLanguageConverter';
import {
  createCombineCondition,
  isValueCondition,
  type Condition,
} from '@/types/advanceFilter';
import { parseJson } from '@/utils/string';

const historyEvents = ['popstate'];

const normalizeFilter = (filter: Condition | undefined) => {
  if (!filter) {
    return undefined;
  }

  if (isValueCondition(filter)) {
    return createCombineCondition([filter]);
  }

  return filter.conditions.length === 0 ? undefined : filter;
};

const ViewParametersContext = createContext<
  | {
      params: () => Record<string, unknown>;
      setParams: Dispatch<SetStateAction<Record<string, unknown>>>;
      setInitialValues: (initialValues: Record<string, unknown>) => void;
      setParamsWithoutRender: (newParams: Record<string, unknown>) => void;
    }
  | undefined
>(undefined);

const paramTypes: Record<string, string> = {
  pageIndex: 'number',
  pageSize: 'number',
  query: 'condition',
  startDate: 'date',
  endDate: 'date',
};

export const deserializeSearchParams = (searchParams: URLSearchParams) => {
  return [...searchParams.entries()].reduce<Record<string, unknown>>(
    (agg, [key, value]) => {
      switch (paramTypes[key]) {
        case 'number': {
          const parsed = parseInt(value || '', 10);
          if (!isNaN(parsed)) {
            agg[key] = parsed;
          }
          break;
        }
        case 'boolean': {
          if (value === 'true') {
            agg[key] = true;
          } else if (value === 'false') {
            agg[key] = false;
          }
          break;
        }
        case 'condition': {
          if (value) {
            agg[key] = parseJson(value);
          }
          break;
        }
        case 'date': {
          if (value) {
            agg[key] = new Date(value);
          }
          break;
        }
        default: {
          agg[key] = value;
        }
      }

      return agg;
    },
    {}
  );
};

export const ViewParametersProvider = ({ children }: PropsWithChildren) => {
  const searchParams = useSearchParams();
  const deserializedSearchParams = deserializeSearchParams(searchParams);
  const params = useRef<Record<string, unknown>>(deserializedSearchParams);

  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);

  const setParams = (newParams: Record<string, unknown>, render: boolean) => {
    if (newParams?.query) {
      newParams.query = normalizeFilter(newParams.query as Condition);
    }

    if (params.current.query !== newParams.query) {
      newParams.pageIndex = 0;
    }

    params.current = newParams;
    if (render) {
      forceUpdate();
    }
  };

  return (
    <ViewParametersContext.Provider
      value={{
        params: () => params.current,
        setInitialValues: (initialValues) => {
          setParams(initialValues, true);
        },
        setParamsWithoutRender: (newParams) => {
          setParams(newParams, false);
        },
        setParams: (p) => {
          const newParams =
            typeof p === 'function' ? p(params.current || {}) : p;
          setParams(newParams, true);
        },
      }}
    >
      {children}
    </ViewParametersContext.Provider>
  );
};

export const useViewParametersSafe = () => {
  const context = useContext(ViewParametersContext);

  if (!context) {
    throw new Error(
      'useViewParameters must be used within a ViewParametersProvider'
    );
  }
  return context;
};

export const useBrowserHistory = ({
  params,
  setParams,
}: {
  params: Record<string, unknown>;
  setParams: (state: Record<string, unknown>) => void;
}) => {
  const pathname = usePathname();
  const firstRender = useRef(true);

  useEffect(function () {
    const onBrowserHistoryChange = () => {
      const newParams = deserializeSearchParams(
        new URLSearchParams(window.location.search)
      );
      setParams(newParams);
    };
    const abortController = new AbortController();
    historyEvents.forEach((event) =>
      window.addEventListener(event, onBrowserHistoryChange, {
        signal: abortController.signal,
      })
    );

    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newUrl = Object.keys(params).length
      ? `${pathname}?${new URLSearchParams(serialize(params)).toString()}`
      : pathname;

    if (newUrl !== window.location.pathname + window.location.search) {
      if (firstRender.current) {
        // when setting the history for the first time, we are replacing the current url
        // so we won't have another back history due to adding the default params.
        window.history.replaceState(
          { ...window.history.state, as: newUrl, url: newUrl },
          '',
          newUrl
        );
      } else {
        window.history.pushState(
          { ...window.history.state, as: newUrl, url: newUrl },
          '',
          newUrl
        );
      }
    }
    firstRender.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, params]);
};

const serialize = (params: Record<string, unknown>) => {
  return Object.entries(params).reduce<Record<string, string>>(
    (acc, [key, value]) => {
      if (key === 'query') {
        if (value) {
          acc[key] = toJsonText(value as Condition);
        }
      } else if (value instanceof Date) {
        acc[key] = value.toISOString();
      } else {
        acc[key] = String(value);
      }
      return acc;
    },
    {}
  );
};
